<template>
    <div>
        <b-card class="text-center" v-if="!IsSystemSMTPAllowed && IsSystemSMTPAllowed!=null" style="height:65px !important;">
            <b class="alert-danger">Your Landingly email account has been suspended.</b>
        </b-card>
        <b-card-code>
            <h4 class="text-center">Welcome to Landingly</h4>
            <!--<b-embed type="iframe"

                     aspect="16by9"
                      />-->
            <div class="justify-content-center d-flex flex-column my-3">
                <iframe :src="videoUrl" class="frame-video"
                        allowfullscreen></iframe>
            </div>

            <div class="text-center my-2">
                <router-link to="/projects">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="button"
                              class="btn mr-1"
                              variant="primary">
                        Continue
                    </b-button>
                </router-link>
            </div>

        </b-card-code>

    </div>
</template>

<style scoped>
    .card-body {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .frame-video {
        max-width: 810px;
        width: 100%;
        border: 0;
        height: 400px;
        margin: 0 auto;
    }
    /*.card .card-header {
        justify-content: center !important;
        margin: 25px 0px -10px 0px !important;
    }*/

    /* .card {
        height: 750px;
    }

    .embed-responsive .embed-responsive-item {
        top: 35px !important;
        width: 85% !important;
        height: 65% !important;
        margin-left: 8%;
    }*/
</style>

<script>
    import BCardCode from '@core/components/b-card-code'
    import { BEmbed, BCardText, BCard, BButton } from 'bootstrap-vue'
    import jwt from '@/auth/jwt/useJwt'
    /*import { codeTypes } from './code'*/
    import Ripple from "vue-ripple-directive";

    export default {
        metaInfo: {
            title: 'Landingly'
        },
        components: {
            BCardCode,
            BEmbed,
            BCardText,
            BCard,
            BButton
        },
        directives: {
            Ripple
        },
        data() {
            return {
                data: {},
                IsSystemSMTPAllowed: localStorage.getItem('IsSystemSMTPAllowed'),
                videoUrl: null,
                showContent: true,
            }
        },
        beforeCreate() {
            
        },
        created() {
            setTimeout(() => {
                this.videoUrl = localStorage.getItem('videoUrl');
            }, 1000);
        }
    }
</script>
